import { OnlineStatusProvider } from '@/connect-context/online-status'
import SegmentProvider from '@/connect-context/tracking/segment'
import theme from '@/connect-theme'
import OauthContext from '@/pages/oauth/context'
import FirebaseProvider from '@/utils/firebase/provider'
import { isInStandaloneMode } from '@/utils/helpers/pwa'
import { ChakraProvider } from '@chakra-ui/react'
import { Analytics } from '@vercel/analytics/react'
import { lazy, Suspense, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Toaster } from 'sonner'
import { BrandKitStyles, buttonVariants, Spinner } from 'tailwind-ui'
import { useColorMode } from '@/hooks/use-color-mode'
import AlertProvider from '@/components/alerts'
import useSaveLastRoute from '@/helpers/router'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { QueryParamProvider } from 'use-query-params'
import qs from 'query-string'

const AppNotifications = lazy(
  () => import('@/components/ProfilePage/AppNotifications')
)

const queryStringifyOptions = {
  searchStringToObject: qs.parse,
  objectToSearchString: qs.stringify,
}

const Offline = lazy(() => import('@/common/offline'))

function RootLayout({ children = <Outlet /> }: { children?: React.ReactNode }) {
  useColorMode()

  const { pathname } = useLocation()
  const push = useNavigate()
  useSaveLastRoute()

  useEffect(() => {
    if (!isInStandaloneMode()) return
    const lastRoute = localStorage.getItem('last-visited-pathname')
    // If we have a saved route, redirect there instead of defaulting to, say, "/"
    if (lastRoute) {
      // Replace the current history entry so the user can still navigate back
      push(lastRoute, { replace: true })
    }
  }, [push])

  return (
    <SegmentProvider>
      <OauthContext>
        <Analytics />

        <FirebaseProvider>
          <Suspense fallback={<></>}>
            {'Notification' in window ? <AppNotifications /> : null}
          </Suspense>
          <ChakraProvider theme={theme}>
            <OnlineStatusProvider>
              <AlertProvider>
                <Toaster
                  theme="system"
                  closeButton
                  position="top-right"
                  visibleToasts={3}
                  icons={{ loading: <Spinner size="sm" /> }}
                  toastOptions={{
                    unstyled: true,
                    className: '',
                    classNames: {
                      success: 'fill-green-500',
                      cancelButton:
                        'ml-auto rounded-lg self-start backdrop-blur-none text-xs p-1 font-medium text-white/80 dark:text-black/80',
                      actionButton: buttonVariants({
                        variant: 'primary',
                        brandKit: true,

                        className: 'ml-auto rounded-lg self-start',
                      }),
                      description: 'text-xs',

                      closeButton:
                        'self-start border border-black/10 dark:border-white/10 !text-black !bg-white/80 dark:!text-white dark:!bg-black/80 z-[9999px] backdrop-blur-lg ',
                      title: 'text-sm line-clamp-2',
                      toast:
                        'z-[9999px] duration-250 shadow-lg right-0 items-start insert-0 bg-black/90 dark:bg-white/90 backdrop-blur-lg dark:hover:bg-white hover:bg-black  dark:text-black/90 text-white/90  py-2 pl-4 pr-2 flex items-center gap-x-2 !min-w-80 rounded-xl',
                    },
                  }}
                />

                <BrandKitStyles
                  ignoreBody
                  brand={{
                    backgroundImage: '',
                    interfaceColor: '#ff3a9b',
                    roundInputs: true,
                    background: 'white',
                    heading_font_family: 'Montserrat',
                    body_font_family: 'Montserrat',
                  }}
                />
                <QueryParamProvider
                  adapter={ReactRouter6Adapter}
                  options={queryStringifyOptions}
                >
                  {children}
                </QueryParamProvider>
                <Suspense fallback={<></>}>
                  <Offline />
                </Suspense>
              </AlertProvider>
            </OnlineStatusProvider>
          </ChakraProvider>
        </FirebaseProvider>
      </OauthContext>
    </SegmentProvider>
  )
}

export default RootLayout
